const { addClass, hasClass, queryFirst } = require('./domUtil');

/**
 * Tokenize strings
 *
 * @param  {string | Object} string Resource message that need to be tokenized
 * @param  {string | Object} values Values that need to be replaced.
 * @param  {string | Object} leftToken Left token type with default as {{
 * @param  {string | Object} rightToken Right token type with defaulat as }}
 * @return  {string} Updated string.
 */
exports.tokenize = (string, values, leftToken = '{{', rightToken = '}}') => {
    if (typeof values !== 'object') return string;
    const operators = /([{}+.\-!?[\]])/g;
    return string.replace(new RegExp(leftToken.replace(operators, '\\$1') + '[\\r\\n\\s\\t]*([\\s\\S]+?)[\\r\\n\\s\\t]*' + rightToken.replace(operators, '\\$1'), 'igm'), (_, code) => {
        return values[code] || '';
    });
};

/**
 *  Transforms all text to a kebab-case string.
 *  @param {string} text - The text to transform
 *  @returns {string} - The transformed string value
 *  @example toKebabCase('.suggestions-related-products', ['.product-tile', '.link']) // suggestions-related-products-product-tile-link
 */
const toKebabCase = exports.toKebabCase = (...values) => values.map(value => {
    if (!Array.isArray(value)) value = [value];
    return value.map(text => typeof text === 'undefined' ? '' : String(text).toLowerCase().replace(/\W/g, '-').trim()).join('-');
}).join('-').replace(/-+/g, '-').replace(/^-+|-+$/g, '');

/**
 *  Transforms any input into a hash value.
 *  @param {*} input - The input to transform
 *  @returns {string} - The transformed string hash value
 *  @example hashValue(function(a){ return a++; }) // 66756e6374696f6e2861297b2072657475726e20612b2b3b207d
 */
exports.hashValue = input => {
    let value = JSON.stringify(String(input));

    if (value === '"[object Object]"') {
        // Sort the object first so hashes match despite key order
        value = JSON.stringify(Object.keys(input).sort().reduce((result, key) => {
            result[key] = input[key];
            return result;
        }, {}));
    }

    if (value.startsWith('"') && value.endsWith('"')) {
        value = value.substr(1, value.length - 2);
    }

    return value.split("").map(digit => digit.charCodeAt(0).toString(16)).join('');
};

/**
 *  Transforms all text to a valid dataset key.
 *  @param {string} text - The text to transform
 *  @returns {string} - The transformed string value
 *  @example toDatasetKey('.suggestions-related-products', ['.product-tile', '.link']) // suggestionsRelatedProductsProductTileLink
 */
exports.toDatasetKey = (...values) => toKebabCase(...values).split('-').map((text, index) => index === 0 ? text : text.charAt(0).toUpperCase() + text.substr(1)).join('');

/**
 * Format dynamic resource messages.
 *
 * @param  {string | Object} string Resource message that need to be tokenized
 * @param  {string | Object} tokens Tokens that need to be replaced.
 * @return  {string} Updated string.
 */
exports.formatMessage = (string, ...tokens) => {
    return this.tokenize(
        string,
        tokens.reduce((result, item, index) => {
            result[index] = item;
            return result;
        }, {}),
        '{',
        '}'
    );
};

/**
 * Safely gets nested object properties. Returns the value if found, undefined if not found.
 * @param {*} obj The parent object in which the property exists
 * @param {*} keyString String denoting where in the parent object your target property should exist
 * @param  {...any} replaceValues Values in the keyString to replace -- reference in the keyString with a number encapsulated in {} (e.g. {0}, {1}, etc)
 * @return {Object} returns nested object properties
 */
exports.getNestedValue = function (obj, keyString, ...replaceValues) {
    const keys = keyString.split(/\[|\]|\./).filter(el => el !== '');

    return keys.reduce((o, i) => (o || {})[/\{\d+\}/.test(i) ? replaceValues[i.split(/\{|\}/)[1]] : i], obj);
};

/**
 * Ensures an event handler is only bound to an element once
 * @param {HTMLElement} element The element to bind the event to
 * @param {string} eventType The type of event
 * @param {function} handler The handler to execute when the event occurs, or the immediate callback if not defined
 * @param {string} initFlag The name of the flag to use for init
 */
exports.initOnce = (element, eventType = '', handler, initFlag = '') => {
    const flag = 'init' + initFlag + eventType.toLowerCase();

    if (!element || element.dataset[flag]) return;

    element.dataset[flag] = true;
    if (eventType) {
        element.addEventListener(eventType, handler);
    } else {
        handler();
    }
};

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
exports.appendToUrl = (url, params) => {
    let newUrl = url;
    newUrl +=
        (newUrl.indexOf('?') !== -1 ? '&' : '?') +
        Object.keys(params)
            .map(key => key + '=' + encodeURIComponent(params[key]))
            .join('&');

    return newUrl;
};

/**
 * This method performs an ajax call
 * @param {string} url endpoint url
 * @param {string} type ajax method type
 * @param {Object} data data for an ajax call
 * @param {function} onSuccess success call back function
 * @param {function} onError error call back function
 * @return {function} returns ajax function
 */
exports.getJSON = (url, type, data = {}, onSuccess = function () { }, onError = function () { }) => {
    return $.ajax({
        url,
        type,
        dataType: 'json',
        data,
        success: onSuccess,
        error: onError
    });
};

/**
 * This method renders geo location
 * @param {function} successCallback Success callback function
 * @param {Object} mixin additional parameters
 * @param {function} errorCallback Error callback function
 */
exports.geolocate = (successCallback, mixin = {}, errorCallback) => {
    if (!navigator.geolocation) return;
    const data = Object.assign({}, mixin);
    const successHandler = response => {
        const { coords } = response;
        if (coords) {
            const { latitude, longitude } = coords;
            data.latitude = latitude;
            data.longitude = longitude;
        }

        if (successCallback) {
            successCallback(data);
        }
    };
    const errorHandler = error => {
        if (errorCallback) {
            errorCallback(data);
        }
    };
    navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
};

/**
 * This method stores data in key-value pair into browser's localStorage
 * @param {string} key Identifier to be stored
 * @param {string | Object} value Value to be stored
 */
exports.setItemInLocalStorage = function (key, value) {
    if (!window.localStorage || !key) {
        return;
    }

    localStorage.setItem(key, JSON.stringify(value));
};

/**
 * This method stores data into browser's localStorage
 * @param {string} key Identifier for retrieving the value
 * @return {string | Object | boolean} returns parsed value
 */
exports.getItemFromLocalStorage = function (key) {
    if (!window.localStorage || !key) {
        return false;
    }

    const value = localStorage.getItem(key);

    if (!value) {
        return false;
    }

    return JSON.parse(value);
};

/**
 * This method removes data from browser's localStorage
 * @param {string} key Identifier
 */
exports.removeItemFromLocalStorage = function (key) {
    if (!window.localStorage || !key) {
        return;
    }

    localStorage.removeItem(key);
};

/**
 * This method formats phone number
 * @param {HTMLElement} element - current element for which formatting should be one
 */
exports.formatPhoneNumber = function (element) {
    const Cleave = require('cleave.js').default;
    if (element) {
        let selector = '#' + element.id;
        setTimeout(() => {
            var cleave = new Cleave(selector, {
                delimiters: ['(', ') ', '-'],
                blocks: [0, 3, 3, 9],
                numericOnly: true
            });

            $(element).data('cleave', cleave);
        }, 0);
    }
}

/**
 * @function
 * @desc Determines if the device that is being used is mobile
 * @returns {Boolean}
 */
exports.isMobile = function () {
    const mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
    let idx = 0;
    let isMobile = false;
    const userAgent = navigator.userAgent.toLowerCase();

    while (mobileAgentHash[idx] && !isMobile) {
        isMobile = userAgent.indexOf(mobileAgentHash[idx]) >= 0;
        idx++;
    }
    return isMobile;
};

/**
 * @function
 * @desc Fixes position sticky scrolling behavior for elements with greater height than widnow height. Ensures content is scrollable above the fold
 * @param {Array} items - items to set scroll height position
 * @param {Boolean} isTopOnly - Whether stick to top side only
 * @param {Number} topOffset - additional top space
 */
exports.stickyScrollPosition = (items, isTopOnly, topOffset = 0) => {
    const $window = $(window);
    const handleStickyPositionOnScroll = item => {
        let ticking = false;
        const detailStickyScroll = () => {
            ticking = false;
            const itemHeight = item.getBoundingClientRect().height;
            const windowHeight = window.innerHeight;
            const headerHeight = $('.main-header').outerHeight();
            const newTop = itemHeight - windowHeight + headerHeight;

            if (!isTopOnly && itemHeight > windowHeight - headerHeight) {
                item.style.setProperty('--top', `${-newTop}px`);
            } else {
                item.style.setProperty('--top', `${headerHeight + topOffset}px`);
            }
        };
        const requestTick = () => {
            if (!ticking) {
                requestAnimationFrame(detailStickyScroll);
            }
            ticking = true;
        };
        const onScroll = () => {
            requestTick(item);
        };
        $window.scroll(onScroll);
    };

    items.forEach(item => {
        handleStickyPositionOnScroll(item);
    });
};

/**
 * Determines whether the user is browsing with an old/unsupported browser.
 * @returns {boolean} True if the browser is old/unsupported.
 */
exports.isUnsupportedBrowser = () => {
    const { userAgent } = navigator;
    const sitePrefs = document.getElementById('site-prefs');

    if (!sitePrefs) return false;

    let unsupportedBrowserTypes;

    if (sitePrefs.dataset) {
        ({ unsupportedBrowserTypes } = sitePrefs.dataset);
    } else {
        // For old IE
        unsupportedBrowserTypes = sitePrefs.getAttribute('data-unsupported-browser-types');
    }

    return JSON.parse(unsupportedBrowserTypes).some(function (uaFragment) {
        return ~userAgent.indexOf(uaFragment);
    });
};

/**
 * Get remaining time object for given time string 
 * @param {string} endtime - remaining time string comes as parameter
 * @return {Object} remainTimeObject - Date Object with day, hours, minutes, Sec
 */
exports.getTimeRemaining = (endtime) => {
    const total = Date.parse(endtime) - Date.now();
    const seconds = Math.floor((total / 1000) % 60).toString();
    const minutes = Math.floor((total / 1000 / 60) % 60).toString();
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24).toString();
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
        total,
        days,
        hours,
        minutes,
        seconds
    };
};


/**
 * Converts the range to Absolute, according to the Rounding Rules configured on Global-e side.
 * @param {number} price - Price
 * @param {Object} range - Range
 * @returns {Object} - result Absolute
 */
function convertRangeToAbsolute(price, range) {
    let result;
    let intPart;
    let base;
    if (range.RangeBehavior === 1) {
        // range already has absolute behavior
        result = range;
    } else {
        result = {};
        result.RangeBehavior = range.RangeBehavior;
        result.RoundingExceptions = [];
        result.From = range.From;
        result.To = range.To;

        intPart = Math.floor(price);

        if (range.RangeBehavior === 2) {
            // Relative Decimal
            result.LowerTarget = intPart - 1;
            result.LowerTarget += range.LowerTarget;
            result.UpperTarget = intPart + range.UpperTarget;
            result.Threshold = intPart + range.Threshold;
            for (let i = 0; i < range.RoundingExceptions.length; i++) {
                let ex = range.RoundingExceptions[i];
                ex.ExceptionValue += intPart;
                result.RoundingExceptions.push(ex);
            }
        } else if (range.RangeBehavior === 3) {
            // Relative Whole
            if (range.TargetBehaviorHelperValue === 0) {
                range.TargetBehaviorHelperValue = 10; // eslint-disable-line no-param-reassign
            }
            base = Math.floor(price / range.TargetBehaviorHelperValue) * range.TargetBehaviorHelperValue;
            result.LowerTarget = (base - range.TargetBehaviorHelperValue) + range.LowerTarget;
            result.UpperTarget = base + range.UpperTarget;
            result.Threshold = base + range.Threshold;
            for (let i = 0; i < range.RoundingExceptions.length; i++) {
                let ex = range.RoundingExceptions[i];
                ex.ExceptionValue += base;
                result.RoundingExceptions.push(ex);
            }
        } else if (range.RangeBehavior === 4) {
            // Nearest target
            if (range.TargetBehaviorHelperValue === 0) {
                range.TargetBehaviorHelperValue = 5; // eslint-disable-line no-param-reassign
            }
            base = Math.floor(price / range.TargetBehaviorHelperValue) * range.TargetBehaviorHelperValue;
            result.LowerTarget = (base - 1) + range.LowerTarget;
            result.UpperTarget = ((base - 1) + range.TargetBehaviorHelperValue) + range.UpperTarget;
            result.Threshold = base + range.Threshold;
            for (let i = 0; i < range.RoundingExceptions.length; i++) {
                let ex = range.RoundingExceptions[i];
                ex.ExceptionValue += base;
                result.RoundingExceptions.push(ex);
            }
        }
    }
    return (result || null);
}

/**
 * Rounds the given price, according to the Rounding Rules configured on Global-e side
 * @param {number} price - Price
 * @param {Object} range - Range
 * @returns {number} - Absolute
 */
function absoluteRounding(price, range) {
    let result = null;
    // check exceptions
    if (range.RoundingExceptions.length > 0) {
        for (let i = 0; i < range.RoundingExceptions.length; i++) {
            let ex = range.RoundingExceptions[i];
            if (price === ex.ExceptionValue) {
                result = price;
            }
        }
    }
    // no exception was found
    if (!result) {
        // check threshold
        if (price < range.Threshold) {
            result = range.LowerTarget;
        } else {
            result = range.UpperTarget;
        }
    }
    return result;
}

/**
 * Calculate and returns currecy price for Stylitics widget
 * @param {string} gePrice - price
 * @param {Object} roundingRanges - globale rouding rules json
 * @return {string} - Rounded price
 */
function roundPrice(gePrice, roundingRanges) {
    let range = null;
    let price = gePrice;
    for (let i = 0; i < roundingRanges.length; i++) {
        let rg = roundingRanges[i];
        if (rg.From < price && price <= rg.To) {
            range = rg;
            break;
        }
    }
    if (range) {
        range = convertRangeToAbsolute(price, range);
        price = absoluteRounding(price, range); // eslint-disable-line no-param-reassign
    }
    if (price < 0) {
        price = 0; // eslint-disable-line no-param-reassign
    }
    return price;
}

/**
 * Calculate and returns currecy price for Stylitics widget
 * @param {string} price - list price
 * @param {string} salesPrice - sales price
 * @return {Object} - localized price object with list and sales price
 */
exports.globaleCalculation = (price, salesPrice) => {
    const countryCurrency = window.currencySymbol;
    if (price.innerText.indexOf(countryCurrency) === -1) {
        let salesPriceText;
        let geSalesPrice;

        let priceText = price.innerText.replace('$', '');
        let gePrice = priceText;

        if (salesPrice) {
            salesPriceText = salesPrice.innerText.replace('$', '');
            geSalesPrice = salesPriceText;
        }

        const countryCoefficientIncludeVAT = window.countryCoefficientIncludeVAT;
        const countryVATRate = parseFloat(window.countryVATRate);
        const merchantTaxRate = parseFloat(window.merchantTaxRate);
        const currenyRate = parseFloat(window.currenyRate);
        const coefficientRate = parseFloat(window.coefficientRate);

        if (['2', '4', '6'].indexOf(countryCoefficientIncludeVAT) !== -1) {
            gePrice += gePrice * countryVATRate;
            if (geSalesPrice) {
                geSalesPrice += (geSalesPrice * countryVATRate);
            }
        } else if (['0', '8'].indexOf(countryCoefficientIncludeVAT) !== -1
            || (parseInt(countryCoefficientIncludeVAT) === 6 && window.useCountryVAT)) {
            gePrice /= (1 + (merchantTaxRate / 100));
            geSalesPrice /= (1 + (merchantTaxRate / 100));
            if (parseInt(countryCoefficientIncludeVAT) === 6) {
                gePrice += gePrice * countryVATRate;
                if (geSalesPrice) {
                    geSalesPrice += geSalesPrice * countryVATRate;
                }
            }
        }

        gePrice = gePrice * currenyRate * coefficientRate;
        geSalesPrice = geSalesPrice ? geSalesPrice * currenyRate * coefficientRate : null;

        const globaleRoundingRanges = JSON.parse(JSON.stringify(window.roundingRanges));
        if (globaleRoundingRanges) {
            const roundingRanges = JSON.parse(globaleRoundingRanges);
            gePrice = roundPrice(gePrice, roundingRanges);
            geSalesPrice = geSalesPrice ? roundPrice(geSalesPrice, roundingRanges) : '';
        }

        return {
            gePrice: countryCurrency + ' ' + gePrice,
            geSalesPrice: countryCurrency + ' ' + geSalesPrice
        };
    }
    return {
        gePrice: price.innerText,
        geSalesPrice: salesPrice ? salesPrice.innerText : ''
    };
};
