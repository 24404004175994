'use-strict'
import { queryFirst, addClass, removeClass, hasClass, queryAll } from './domUtil';
const $body = $('body');
const invalidClass = 'is-invalid';
const emailForm = queryFirst('.inline-email-form');
const emailInput = queryFirst('.inline-email-input');
const errorMessages = queryFirst('.inline-email-errors');
const subscribeButton = queryFirst('.inline-email-submit');

function showError(message) {
    errorMessages.textContent = message;
    emailInput.classList.add(invalidClass);
    return false;
}

function displayMessage(response) {
    $.spinner().stop();
    if (response.success) {
        $('#success-modal').modal('show').on('show.bs.modal',
            function () {
                $(this).find('.close').focus();
            }).keydown(function (e) {
                if (e.keyCode === 9) e.preventDefault();
            });
        emailInput.classList.remove(invalidClass);
    } else {
        showError(response.msg);
    }
    subscribeButton.disabled = false;
    subscribeButton.removeAttribute('aria-disabled');
}

function submitInlineEmail(e) {
    e.preventDefault();
    var url = subscribeButton.dataset.href;
    var emailId = emailInput.value;
    if (!emailId) return showError(emailInput.dataset.missingError);
    emailInput.classList.remove(invalidClass);
    subscribeButton.disabled = true;
    subscribeButton.setAttribute('aria-disabled', "button disabled");
    $.spinner().start();
    $body.trigger('email-submit-button');
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
            emailId: emailId
        },
        success: displayMessage,
        error: displayMessage
    });
}

const init = () => {
    if (emailForm) {
        emailForm.addEventListener('submit', submitInlineEmail);
    }
    $('.expandLink').on('keypress', function (e) {
        if (e.which == 13) { //Enter key pressed
            $(this).click(); //Trigger search button click event
        }
    });
    $('.expandLink').on('click', function (e) {
        e.preventDefault;
        const _thisLink = this;
        const parentEle = _thisLink.closest('.accordion-container');
        const sections = queryAll('.card .content-header', parentEle);
        const contract = hasClass(_thisLink, 'contractLink');
        sections.forEach(function (element) {
            let _thisCTALink = queryFirst('.btn', element);
            $(_thisCTALink).trigger('click');
        });
        if (contract) {
            _thisLink.innerHTML = 'Expand All';
            removeClass(_thisLink, 'contractLink');
        } else {
            _thisLink.innerHTML = 'Close All';
            addClass(_thisLink, 'contractLink');
        }
    });
    $('#privacySecurityPage .pageLink').click(function () {
        var _target = $(this).data('linkto');
        _target = '#' + _target;
        $('html, body').animate({
            scrollTop: $(_target).offset().top - 80
        }, 'slow');
    });
}

document.addEventListener('DOMContentLoaded', () => {
    init();
})